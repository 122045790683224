jQuery(function ($) {

	//loding.jsでの画像読み込みが遅れる対策
	setTimeout(function () {


		// // トップページ - メインビジュアル
		// $('.js-slick-main').slick({
		// 	autoplay: true,
		// 	autoplaySpeed: 3000,
		// 	speed: 800,
		// 	pauseOnHover: true,
		// 	infinite: true,
		// 	lazyLoad: 'progressive',
		// 	dots: true,
		// 	arrows: false,
		// 	// prevArrow: '<span class="slide-arrow prev-arrow"></span>',
		// 	// nextArrow: '<span class="slide-arrow next-arrow"></span>',
		// 	slidesToShow: 1,
		// 	slidesToScroll: 1,

		// 	// レスポンシブ設定
		// 	responsive: [{
		// 		breakpoint: 769,
		// 		settings: {
		// 			slidesToShow: 1,
		// 		}
		// 	}]
		// });

		$('.js-slick-outer-list').slick({
			autoplay: true,
			autoplaySpeed: 5000,
			speed: 800,
			pauseOnHover: true,
			infinite: true,
			lazyLoad: 'progressive',
			dots: true,
			arrows: false,
			prevArrow: '<span class="icomoon icomoon--round icon-Icon-ionic-ios-arrow-back arrow--back"></span>',
			nextArrow: '<span class="icomoon icomoon--round icon-Icon-ionic-ios-arrow-forward arrow--forward"></span>',
			slidesToShow: 3,
			slidesToScroll: 1,
			centerMode: false,
			variableWidth: false,
			// レスポンシブ設定
			responsive: [
				{
					breakpoint: 1023,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 1,
					}
				}
			]
		});

	}, 1);

	//最下部固定バナー
	$(document).ready(function () {
		var topBtn = $('.fixed_bnr');
		topBtn.hide();
		var height = $('.p-top-mv').offset().top;
		$(window).on("scroll", function () {
			if ($(this).scrollTop() > height) {
				topBtn.fadeIn();
			} else {
				topBtn.fadeOut();
			}
		});
	});


});
