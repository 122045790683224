jQuery(function ($) {
	function scrollChk(){
		var scroll = $(window).scrollTop();
		var windowHeight = $(window).height();

			jQuery('.js-in').not('.is-active').each(function(){
					var pos = $(this).offset().top;

					if (scroll > pos - windowHeight){
							$(this).addClass("is-active");
					}
			});
	}
	$(window).scroll(function (){
			scrollChk();
	});
	$('body').on('touchmove', function() {
			scrollChk();
	});
});
